<template>
	<div class="login">
		<div class="title middle">
			<i class="el-icon-s-promotion icon"></i>
			<span class="title__comp">瑞云医</span>
			<span class="title__proj">管理后台</span>
		</div>
		<h4 class="blue middle">© 瑞云医</h4>
		<el-form :rules="dataRule" ref="formData" :model="form" class="login-form" v-loading.lock="isPageLoading"
			@keyup.enter.native="onSubmit">
			<h4>请输入账号和密码</h4>
			<el-form-item size="medium" prop="phone">
				<el-input placeholder="请输入账号" clearable v-model="form.phone"></el-input>
			</el-form-item>
			<el-form-item size="medium" prop="password">
				<el-input placeholder="请输入密码" v-model="form.password" show-password></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click.prevent="onSubmit">登录</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
export default {
	data () {
		return {
			form: {
				phone: '',
				password: ''
			},
			isPageLoading: false,
			dataRule: {
				phone: [
					{ required: true, message: '请输入账号', trigger: 'blur' }
				],
				password: [
					{ required: true, message: '请输入密码', trigger: 'blur' }
				]
			}
		};
	},
	methods: {
		onSubmit () {
			this.$refs.formData.validate((valid) => {
				if (valid) {
					this.isPageLoading = true;
					this.$root.request('login', {
						phone: this.form.phone,
						password: this.form.password,
						accountType: 'PATIENT'
					}).then((data) => {
						if (data) {
							window.localStorage.setItem('smt_ui', JSON.stringify(data));
							setTimeout(() => {
								this.isPageLoading = false;
								this.$root.go('/mnt');
							}, 0);
						}
						this.isPageLoading = false;
					});
				}
			});
		}
	}
};
</script>
<style lang="less" scoped>
	.login {
		margin: 0 auto;
		width: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		background-image: url('../images/pattern.jpg');

		.title {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 30px !important;

			.icon {
				font-size: 36px;
			}

			span {
				font-size: 32px;
				margin-left: 6px;
			}

			&__comp {
				color: #dd5a43;
			}

			&__proj {
				color: #777777;
			}
		}

		.blue {
			color: #478fca;
			text-align: center;
			margin-bottom: 30px !important;
		}

		.middle {
			width: 60%;
			margin: 0 auto;
		}

		&-form {
			background: white;
			width: 29%;
			height: 260px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin: 0 auto;

			.el-input {
				width: 230px;
			}

			h4 {
				color: #478fca;
				font-size: 19px;
				font-weight: lighter;
			}

			.el-button {
				width: 100px;
			}
		}
	}
</style>